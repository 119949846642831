<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    						
                    <tr>
                        <th class="pl-10px">Type</th>
                        <th>Note name</th>
                        <th>Created by</th>
                        <th>Code</th>
                        <th>Short desc</th>
                        <th>Default</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
              
                <tbody>
                    <tr v-for="item in tableItems.data" :key="item.id">

                        <td class="pl-10px">
                            {{ noteTypesInKeyValue[item.type] }}
                        </td>

                        <td>
                            {{ item.name }}
                        </td>

                        <td>
                            {{ item.created_by ? item.created_by.name: '' }}
                        </td>

                        <td>
                            {{ item.code }}
                        </td>

                        <td>
                            {{ item.short_desc }}
                        </td>

                        <td>
                            {{ item.is_default ? 'Yes' : 'No' }}
                        </td>

                        <td>
                            <div class="action">
                                <button @click="editNote(item)" class="btn btn-outline-primary btn-sm print-btn">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button :disabled="loading" @click="deleteNote(item)" class="btn btn-outline-danger btn-sm print-btn">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-if="!tableItems.data.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import { inject } from "vue";
import { useRoute } from "vue-router";
import handleHospital from '@/services/modules/hospital'
import { ref } from "vue";

const { removeNote } = handleHospital();

const props = defineProps({
    tableItems: {
        type: Object,
    },
    onClickToEdit: Function,
    noteTypesInKeyValue: Object,
    onDelete: {
        type: Function,
        default: () => {}
    }
})
const route = useRoute();
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const $emit = defineEmits(['onClickToEdit', 'onDelete'])
const editNote = (item) => {
    $emit('onClickToEdit', item)
}

const deleteNote = async (item) => {

    if(!confirm('Are you sure you want to delete this note?')) return;

    const query = '?company_id=' + route.params.companyId;

    loading.value = true

    try {
        let res = await removeNote(query, item.id);
        if (!res.status) {
            showError(res.message);
            return;
        }
        $emit('onDelete');
        showSuccess(res.message)
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!');
      return;
    }
    showError(err.response.message)
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>

.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

</style>